<template>
  <div>
    <v-card>
      <v-card-title>
        Tags
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fa-search"
          label="Search"
          single-line
          hide-details
          class="mb-5"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="list"
        :search="search"
        sort-by="name"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2 edit" @click="editItem(item)"
                >fa-edit
              </v-icon>
            </template>
            <span>Edit Tag</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small @click="deleteItem(item)" class="delete">fa-trash </v-icon>
            </template>
            <span>Delete Tag</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <Dialog
        :data="editedItem"
        :open="isDialogOpen"
        width="500px"
        height="500px"
        @onClose="handleDialogClose"
      />
    </v-card>
  </div>
</template>

<script>
import Dialog from './dialog.vue';

const getDefaultState = () => ({
  headers: [
    {
      text: 'Tag name',
      align: 'start',
      sortable: true,
      value: 'name'
    },
    { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
  ],
  isDialogOpen: false,
  editedItem: null,
  search: ''
});

export default {
  components: {
    Dialog
  },
  data: () => getDefaultState(),
  computed: {
    list() {
      return this.$store.state.tags.list;
    }
  },

  methods: {
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.isDialogOpen = true;
    },
    async deleteItem(item) {
      if (window.confirm('Are you sure you want to delete this item?')) {
        const id = item.id;
        await this.$store.dispatch('tags/delete', { id });
        await this.$store.dispatch('tags/load');
      }
    },
    handleDialogClose() {
      this.$data.isDialogOpen = false;
      this.$data.editedItem = null;
    }
  }
};
</script>

<style scoped>
::v-deep .v-icon {
  font-size: 16px;
}
::v-deep .v-btn--icon.v-size--default .v-icon {
  font-size: 16px;
}

::v-deep .v-data-footer {
  font-size: 0.7rem;
  padding: 0;
}

::v-deep .v-data-footer__pagination {
  margin: 0 !important;
}
</style>
